export class PerlinNoise {
    constructor() {
      this.grad3 = [
        [1, 1, 0], [-1, 1, 0], [1, -1, 0], [-1, -1, 0],
        [1, 0, 1], [-1, 0, 1], [1, 0, -1], [-1, 0, -1],
        [0, 1, 1], [0, -1, 1], [0, 1, -1], [0, -1, -1]
      ];
      this.p = [];
      this.perm = [];
      this.seed(Math.random());
    }
  
    seed(seed) {
      this.p = Array.from({ length: 256 }, () => Math.floor(seed * 256));
      this.perm = Array.from({ length: 512 }, (_, i) => this.p[i & 255]);
    }
  
    dot(g, x, y, z) {
      return g[0] * x + g[1] * y + g[2] * z;
    }
  
    noise(x, y, z) {
      const floor = Math.floor;
      let X = floor(x) & 255,
        Y = floor(y) & 255,
        Z = floor(z) & 255;
  
      x -= floor(x);
      y -= floor(y);
      z -= floor(z);
  
      const u = this.fade(x),
        v = this.fade(y),
        w = this.fade(z);
  
      const A = this.perm[X] + Y,
        AA = this.perm[A] + Z,
        AB = this.perm[A + 1] + Z,
        B = this.perm[X + 1] + Y,
        BA = this.perm[B] + Z,
        BB = this.perm[B + 1] + Z;
  
      return this.lerp(
        w,
        this.lerp(
          v,
          this.lerp(u, this.grad(this.perm[AA], x, y, z), this.grad(this.perm[BA], x - 1, y, z)),
          this.lerp(u, this.grad(this.perm[AB], x, y - 1, z), this.grad(this.perm[BB], x - 1, y - 1, z))
        ),
        this.lerp(
          v,
          this.lerp(u, this.grad(this.perm[AA + 1], x, y, z - 1), this.grad(this.perm[BA + 1], x - 1, y, z - 1)),
          this.lerp(u, this.grad(this.perm[AB + 1], x, y - 1, z - 1), this.grad(this.perm[BB + 1], x - 1, y - 1, z - 1))
        )
      );
    }
  
    fade(t) {
      return t * t * t * (t * (t * 6 - 15) + 10);
    }
  
    lerp(t, a, b) {
      return a + t * (b - a);
    }
  
    grad(hash, x, y, z) {
      const h = hash & 15;
      const u = h < 8 ? x : y;
      const v = h < 4 ? y : h === 12 || h === 14 ? x : z;
      return ((h & 1) === 0 ? u : -u) + ((h & 2) === 0 ? v : -v);
    }
  }
  