import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db, storage } from '../firebaseauth'; // Import Firestore and Storage instances
import { getStorage, ref, listAll, getDownloadURL, deleteObject, uploadBytes } from 'firebase/storage';
import { FaTrash, FaPlus } from 'react-icons/fa';

const AlbumsList = ({ setAlbumArr }) => {
  const [albums, setAlbums] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAlbumImages, setSelectedAlbumImages] = useState([]);
  const [showImages, setShowImages] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [currentAlbumId, setCurrentAlbumId] = useState(null);
  const [thumbnail, setThumbnail] = useState(null); // State for thumbnail

  const [file, setFile] = useState(null);

  // Fetch albums from Firestore
  const fetchAlbums = async () => {
    try {
      const albumsCollection = collection(db, 'albums');
      const albumsSnapshot = await getDocs(albumsCollection);
      const albumsList = albumsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAlbums(albumsList);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching albums:', error);
      setErrorMessage('Error fetching albums.');
      setLoading(false);
    }
  };

  const fetchAlbumImages = async (albumId) => {
    try {
      const storage = getStorage();
      const imagesRef = ref(storage, `albums/${albumId}/`);
      const imageList = await listAll(imagesRef);

      const urls = await Promise.all(
        imageList.items.map(item => getDownloadURL(item))
      );

      setSelectedAlbumImages(urls);
      setAlbumArr(urls);
      setShowImages(true);
      setCurrentAlbumId(albumId); // Set the current album ID
    } catch (error) {
      console.error('Error fetching album images:', error);
      setErrorMessage('Error fetching album images.');
    }
  };

  const handleAlbumDelete = async (album) => {
    const { id } = album;

    try {
      // Delete album from Firestore
      await deleteDoc(doc(db, 'albums', id));

      // Delete associated images from Storage
      const storage = getStorage();
      const imagesRef = ref(storage, `albums/${id}/`);
      const imageList = await listAll(imagesRef);

      await Promise.all(imageList.items.map(item => deleteObject(item)));

      // Refresh albums
      fetchAlbums();
      setSuccessMessage('Album deleted successfully.');
    } catch (error) {
      console.error('Error deleting album:', error);
      setErrorMessage('Error deleting album.');
    }
  };

  const handleImageUpload = async () => {
    if (!file || !currentAlbumId) {
      setErrorMessage('Please select a file to upload.');
      return;
    }

    try {
      const storage = getStorage();
      const imageRef = ref(storage, `albums/${currentAlbumId}/${file.name}`);

      // Upload the file
      await uploadBytes(imageRef, file);

      // Refresh images after upload
      fetchAlbumImages(currentAlbumId);
      setSuccessMessage('Image uploaded successfully.');
      setFile(null); // Clear the file input
    } catch (error) {
      console.error('Error uploading image:', error);
      setErrorMessage('Error uploading image.');
    }
  };
  // Handle file change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    console.log(selectedFile)
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setThumbnail(event.target.result); // Set the thumbnail to the data URL
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    fetchAlbums();
  }, []);

  if (loading) {
    return <div className="text-center py-10">Loading albums...</div>;
  }



  return (
    <div className="p-5">
      {errorMessage && <div className="text-red-500">{errorMessage}</div>}
      {successMessage && <div className="text-green-500">{successMessage}</div>}
      {showImages ? (
        <div className="mt-5">
          <label className="block mb-3">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => { handleFileChange(e) }}
              className="hidden"
              id="fileInput"
            />
            <span className="flex items-center justify-center cursor-pointer bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200">
              Choose File
            </span>
          </label>
          <div className='flex'>
            {thumbnail && (
              <img src={thumbnail} alt="Thumbnail" className="w-56 h-20 object-cover rounded border-2 border-blue-500 mr-3" />
            )}
            <button
              onClick={handleImageUpload}
              className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
            >
              Upload Image
            </button>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            {selectedAlbumImages.map((url, index) => (
              <img key={index} src={url} alt={`Album Image ${index + 1}`} className="w-full h-auto rounded" />
            ))}
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {albums.length > 0 ? (
            albums.map((album) => (
              <div
                key={album.id}
                className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-200 hover:scale-105 relative"
              >
                <div
                  onClick={() => { handleAlbumDelete(album); }}
                  className='absolute flex items-center justify-center top-2 right-2 text-red-500 p-2 rounded-full bg-gray-100 hover:text-red-600 hover:bg-gray-300' >
                  <FaTrash />
                </div>
                <div className="p-5">
                  <h3 className="text-xl font-semibold mb-2">{album.name}</h3>
                  <p className="text-gray-600">Click to view images</p>
                </div>
                <div className="p-4 border-t">
                  <button
                    onClick={() => fetchAlbumImages(album.id)}
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
                  >
                    View Album
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">No albums available</p>
          )}
        </div>
      )}
      <div className="fixed bottom-5 right-5">
        <button
          onClick={() => setShowImages(!showImages)} // Toggle to view images
          className="bg-blue-500 text-white p-3 rounded-full hover:bg-blue-600 transition duration-200"
        >
          <FaPlus />
        </button>
      </div>
    </div>
  );
};

export default AlbumsList;
