import React, { useEffect, useState } from 'react';

const CreateUser = ({data}) => {
  const [username, setUsername] = useState(data.name);
  const [email, setEmail] = useState(data.email);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    setMessage('');
    setError('');

    // Validate input
    if (!username || !email) {
      setError('Username and email are required.');
      return;
    }

    // Send data to the server
    try {
      const response = await fetch('https://fastify-serverless-function-sand-one.vercel.app/api/mongoconnect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message); // Success message
        setUsername(''); // Clear input
        setEmail(''); // Clear input
      } else {
        setError(data.message || 'Error creating user');
      }
    } catch (err) {
      console.error('Error:', err);
      console.log(error)
      setError('Internal server error');
    }
  };
  useEffect(()=>{
    handleSubmit();
  })
  return (<>{console.log(message)}</>);
};

export default CreateUser;
