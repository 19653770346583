import React, { useState } from 'react';
import { db, storage } from '../firebaseauth'; // Import Firestore and Storage instances
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const ActionBar = () => {
  const [albumName, setAlbumName] = useState('');
  const [albumImages, setAlbumImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const handleAlbumNameChange = (e) => {
    setAlbumName(e.target.value);
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setAlbumImages([...albumImages, ...newImages]);
  };

  const handleCreateAlbum = async () => {
    if (albumName.trim() === '') {
      alert('Please provide a valid album name.');
      return;
    }

    if (albumImages.length === 0) {
      alert('Please upload at least one image.');
      return;
    }

    try {
      setIsUploading(true);

      // Step 1: Add album metadata to Firestore
      const albumRef = await addDoc(collection(db, 'albums'), {
        name: albumName,
        createdAt: new Date(),
      });

      // Step 2: Upload images to Firebase Storage and link to album
      const imageUrls = await Promise.all(albumImages.map(async (image, index) => {
        const storageRef = ref(storage, `albums/${albumRef.id}/${index}-${image.file.name}`);
        const uploadTask = await uploadBytesResumable(storageRef, image.file);
        return getDownloadURL(uploadTask.ref); // Get the download URL of the uploaded image
      }));

      // Step 3: Update Firestore with image URLs
      await addDoc(collection(db, `albums/${albumRef.id}/images`), {
        urls: imageUrls,
      });

      alert('Album created successfully!');
      setAlbumName('');
      setAlbumImages([]);

    } catch (error) {
      console.error('Error creating album:', error);
      alert('Failed to create album.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="p-4 bg-gray-100 border rounded-lg shadow-md">
      <h2 className="text-lg font-semibold mb-4">Create New Album</h2>
      <div className="mb-4">
        <input
          type="text"
          value={albumName}
          onChange={handleAlbumNameChange}
          placeholder="Album Name"
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
      </div>
      <div className="mb-4">
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleImageUpload}
          className="block w-full text-sm text-gray-500
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-lg file:border-0
                      file:text-sm file:font-semibold
                      file:bg-blue-50 file:text-blue-700
                      hover:file:bg-blue-100"
        />
      </div>
      <div className="mb-4">
        <button
          onClick={handleCreateAlbum}
          disabled={isUploading}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 disabled:opacity-50"
        >
          {isUploading ? 'Creating Album...' : 'Create Album'}
        </button>
      </div>
      <div className="mt-4">
        {albumImages.length > 0 && (
          <div>
            <h3 className="text-md font-medium">Uploaded Images:</h3>
            <div className="grid grid-cols-3 gap-2 mt-2">
              {albumImages.map((image, index) => (
                <img
                  key={index}
                  src={image.preview}
                  alt={`Uploaded ${index}`}
                  className="w-full h-24 object-cover rounded-lg border"
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionBar;
