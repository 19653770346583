import React, { useRef, useState } from 'react';

const ScrapePinImages = () => {
  const [pinCode, setPinCode] = useState('');
  const [codes, setCodes] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const currentImages = useRef([]);
  const codesRef = useRef();
  const fetchPinCodes = async () => {
    if (!pinCode) {
      setError('Please provide a Pinterest pin code.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://fastify-serverless-function-sand-one.vercel.app/scrape-pinterest-pins/?url=https://www.pinterest.com/search/pins/?q=${pinCode}`);
      const data = await response.json();

      if (response.ok) {
        setCodes(data.codesarray);
        codesRef.current = data.codesarray;

        console.log(data.codesarray)
      } else {
        setError(data.error || 'Failed to fetch images.');
      }
    } catch (err) {
      setError('An error occurred while fetching images.');
    } finally {
      setLoading(false);
      let x = 0;
      const timerr = setInterval(() => {
        if (x < codesRef.current.length) {
          fetchPinImage(codesRef.current[x])
          const img = document.createElement('img');
          img.src = codesRef.current[x];
          currentImages.current.push(img)
          x++;
        } else {
          clearInterval(timerr)
        }
      }, 5000)
    }
  };

  const fetchPinImage = async (code) => {

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`http://localhost:3001/scrape-pin-images?code=${code}`);
      const data = await response.json();

      console.log(data)
      if (response.ok) {
        setImages((prev) => [...prev, ...data.images]);
        console.log(data.dp)
      } else {
        setError(data.error || 'Failed to fetch images.');
      }
    } catch (err) {
      setError('An error occurred while fetching images.');
    } finally {
      setLoading(false);
    }
  };
  console.log(currentImages.current)
  return (
    <div className="relative top-8 right-0 p-4 z-50 bg-gay-800 text-white">
      <div className="flex items-center justify-center mb-4">
        <div className='mr-2 p-2'>
          {console.log(codes)}
          {/* Loading Element */}
          {loading ? <span></span> : <span></span>}
        </div>
        <input
          type="text"
          className="border rounded w-96 text-gray-600 p-2"
          placeholder="Search Pinterest Image"
          value={pinCode}
          onChange={(e) => setPinCode(e.target.value)}
        />
        <button
          className="bg-blue-500 text-white rounded ml-2 hover:bg-blue-700 p-2"
          onClick={fetchPinCodes}
        >
          {loading ? 'Scraping...' : 'Scrape Images'}
        </button>

      </div>

      {error && <p className="text-resd-500">{error}</p>}

      <div className="grid grid-cols-6 gap-0 mt-4">
        {images.map((image, index) => (
          <img key={index} src={image} style={{ maxWidth: '300px' }} alt={`Scraped ${index + 1}`} className="h-auto rounded m-2" />
        ))}
      </div>
    </div>
  );
};

export default ScrapePinImages;
