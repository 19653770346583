import React, { useRef, useEffect } from "react";
import { PerlinNoise } from './PerlinNoise';
// Import the PerlinNoise class or copy it directly in the file
const noise = new PerlinNoise();

const Matrix = ({ speed = 20, color = "#0FF", alpha = 0.05 }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Set the canvas size
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const fontSize = 18;
    const columns = Math.floor(canvas.width / fontSize); // Number of columns
    const drops = Array(columns).fill(1); // Y coordinate for each column drop

    // Chinese characters and symbols for the Matrix effect
    const matrixChars = "田由甲申甴电甶男甸画甹町甼甽甾甿畀畁畂畃畄畅畆畇畈畉畊畋界畍畎畏畐畑雨雷风龙亀龟星水火山月木心金光気生命石天地日力魂道空天生暗夜海神恶灵魔灵霊符魔力🂡★✦✧⚔⛓♠♣♥♦☯☠⌘☢☣".split("");

    let time = 0; // Time counter for the noise

    // Function to draw the matrix effect
    const draw = () => {
      // Black background with slight transparency for fading effect
      context.fillStyle = `rgba(0, 0, 0, ${alpha})`;
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Loop over all columns
      for (let i = 0; i < drops.length; i++) {
        context.fillStyle = i % 3 === 0 ? '#257f14' : '#0F0'; // Green text
        context.font = `${Math.floor(Math.random() * 18)}px monospace`;
        // Use Perlin noise to influence the drop position
        const perlinValue = noise.noise(i * 0.1, time * 0.1, 0);
        const yPosition = Math.floor(perlinValue * 10);

        // Pick a random character from the matrixChars
        const text = matrixChars[Math.floor(Math.random() * matrixChars.length)];

        // Draw the character at the current position (i * fontSize, drops[i] * fontSize)
        context.fillText(text, i * fontSize, (drops[i] + yPosition) * fontSize);

        // Randomly reset the drop to the top or move it downward
        if (drops[i] * fontSize > canvas.height && Math.random() > 0.95) {
          drops[i] = 0; // Reset the drop to the top
        }

        // Move the drop downward
        drops[i]++;
      }

      // Update the time counter for the noise
      time += 0.02;
    };

    // Function to animate the matrix effect
    const update = () => {
      draw();
      setTimeout(() => {
        requestAnimationFrame(update);
      }, speed);
    };

    update();

    // Resize canvas on window resize
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [speed, color, alpha]);

  return (
    <div className="relative w-screen h-screen">
      {/* Modal to enter access code */}
      <div className="fixed z-50 w-screen h-screen flex items-center justify-center text-center bg-opacity-50 backdrop-blur">
        <div className="w-96 h-32 border border-green-500 flex items-center justify-center text-center text-green-500 text-2xl bg-opacity-80 shadow-lg">
          <h2 className="font-customFont">Enter Access Code</h2>
        </div>
      </div>
      
      {/* Canvas Background */}
      <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full"/>
    </div>
  );
  

};

export default Matrix;
