// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";

import { getStorage } from "firebase/storage";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkCYch6QhEMCeUZg_EaRJQLDD1u042pm8",
  authDomain: "my-gallery-a7e95.firebaseapp.com",
  projectId: "my-gallery-a7e95",
  storageBucket: "my-gallery-a7e95.appspot.com",
  messagingSenderId: "49478870300",
  appId: "1:49478870300:web:b037fcb57e75d17d0306d9",
  measurementId: "G-0QTCPT0LXE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const storage = getStorage(app);

// Initialize Firestore
const db = getFirestore(app);

// Example: Adding a document to Firestore
export async function addLink(link) {
  try {
    console.log(collection(db, "url"))
    const docRef = await addDoc(collection(db, "url"), {
        link: link,
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}


export async function getLinks() {
  try {
    const querySnapshot = await getDocs(collection(db, "url"));
    const linksArray = [];

    querySnapshot.forEach((doc) => {
      // Push each document's data along with its ID into the array
      linksArray.push({ id: doc.id, ...doc.data() });
    });

    return linksArray;        // Return the array
  } catch (e) {
    console.error("Error retrieving documents: ", e);
    return [];
  }
}

export async function UploadImages(arr){

    arr.map((value)=>{
      addLink(value)
    })
  } 

export function shuffleArray(array) {
    // Loop through the array from the last element to the first
    for (let i = array.length - 1; i > 0; i--) {
      // Generate a random index between 0 and i
      const j = Math.floor(Math.random() * (i + 1));
      // Swap elements at index i and j
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
 export async function initImages(uPdateArraImgUlr,arrset) {
     let dbarrr=[]
     let arrLinks=[]
  if (arrset) {
    uPdateArraImgUlr(shuffleArray(arrset))
  }else{
    dbarrr = await getLinks()
    dbarrr.map((src)=>{
      arrLinks.push(src.link)  
    })
    uPdateArraImgUlr(shuffleArray(arrLinks))
  }
  
  }
  export { db, storage };
