import React, { useState } from 'react';

const PfpExamples = ({ src ,size}) => {
    const [rimColor, setRimColor] = useState('white');
    const [bgColor, setBgColor] = useState('bg-gray-800');
    const [yOffset, setYOffset] = useState(0); // New state for Y position

    return (
        <ul style={{ backgroundColor: bgColor ,transform:`scale(${size/10})`}} className={`sm:fixed lg:absolute lg:top-4 top-20 left-0 space-x-4 space-y-4 p-4 rounded-lg z-50`}>
            <li>
                <div className=''>
                    {/* Rim Color Picker */}
                    <div className="flex items-center space-x-4 mt-2">
                        <label htmlFor="colorPicker" className="cursor-pointer flex items-center space-x-2 text-white">
                            <div className="w-12 h-12 rounded-lg border-2 border-gray-300" style={{ backgroundColor: rimColor }}></div>
                            <span>Rim Color:</span>
                        </label>
                        <input
                            id="colorPicker"
                            type="color"
                            value={rimColor}
                            onChange={(ev) => setRimColor(ev.target.value)}
                            className="opacity-0 w-0 h-0"
                        />
                    </div>

                    {/* Background Color Picker */}
                    <div className="flex items-center space-x-4 mt-2">
                        <label htmlFor="colorPickerBg" className="cursor-pointer flex items-center space-x-2 text-white">
                            <div className="w-12 h-12 rounded-lg border-2 border-gray-300" style={{ backgroundColor: bgColor }}></div>
                            <span>Background Color:</span>
                        </label>
                        <input
                            id="colorPickerBg"
                            type="color"
                            value={bgColor}
                            onChange={(ev) => setBgColor(ev.target.value)}
                            className="opacity-0 w-0 h-0"
                        />
                    </div>

                    {/* Y Position Slider */}
                    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-2 sm:space-y-0">
                        <label htmlFor="yPosition" className="font-semibold text-gray-300 sm:text-lg">
                            <input
                                id="yPosition"
                                type="range"
                                min="-100"
                                max="100"
                                value={yOffset}
                                onChange={(ev) => setYOffset(ev.target.value)}
                                className="w-full sm:w-2/3 bg-gray-200 rounded-lg overflow-hidden appearance-none h-2 cursor-pointer transition-all hover:bg-gray-300 focus:ring-2 focus:ring-blue-500"
                                />
                                <span>{` Y Axis`}</span>
                        </label>
                    </div>
                    
                </div>
            </li>
            <li>
                {/* Rounded Profile Picture with Background */}
                <div className="flex flex-col items-center">
                    <div
                        style={{
                            border: `3px solid ${rimColor}`,
                            backgroundImage: `url(${src})`,
                            backgroundPositionY: `${yOffset}px`, // Apply Y offset
                            backgroundSize: 'cover',
                            width: '96px', // 24 * 4 (width and height adjusted for the border)
                            height: '96px',
                            borderRadius: '50%', // For rounded effect
                            position: 'relative',
                        }}
                    />
                </div>
            </li>

            {/* Squared Profile Picture */}
            <li>
                <div className="flex flex-col items-center">
                    <div
                        style={{
                            backgroundImage: `url(${src})`,
                            backgroundPositionY: `${yOffset}px`, // Apply Y offset
                            backgroundSize: 'cover',
                            width: '96px', // 24 * 4
                            height: '96px',
                        }}
                    />
                </div>
            </li>

            {/* Framed Profile Picture */}
            <li>
                <div className="flex flex-col items-center">
                    <div style={{ border: `3px solid ${rimColor}`, padding: '8px', borderRadius: '4px' }}>
                        <div
                            style={{
                                backgroundImage: `url(${src})`,
                                backgroundPositionY: `${yOffset}px`, // Apply Y offset
                                backgroundSize: 'cover',
                                width: '80px', // 24 * 4 - border padding
                                height: '80px',
                            }}
                        />
                    </div>
                </div>
            </li>

            {/* Circular with Shadow */}
            <li>
                <div className="flex flex-col items-center">
                    <div
                        style={{
                            backgroundImage: `url(${src})`,
                            backgroundPositionY: `${yOffset}px`, // Apply Y offset
                            backgroundSize: 'cover',
                            width: '96px',
                            height: '96px',
                            borderRadius: '50%',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                        }}
                    />
                </div>
            </li>

            {/* Border with Gradient */}
            <li>
                <div className="flex flex-col items-center">
                    <div className="p-1 rounded-full bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
                        <div
                            style={{
                                backgroundImage: `url(${src})`,
                                backgroundPositionY: `${yOffset}px`, // Apply Y offset
                                backgroundSize: 'cover',
                                width: '96px',
                                height: '96px',
                                borderRadius: '50%',
                            }}
                        />
                    </div>
                </div>
            </li>

            {/* Hexagonal Style */}
            <li>
                <div className="flex flex-col items-center">
                    <div className="hexagon">
                        <div
                            style={{
                                backgroundImage: `url(${src})`,
                                backgroundPositionY: `${yOffset}px`, // Apply Y offset
                                backgroundSize: 'cover',
                                width: '96px',
                                height: '96px',
                            }}
                        />
                    </div>
                </div>
            </li>

            {/* Blurred Background */}
            <li>
                <div className="flex flex-col items-center">
                    <div className="relative w-24 h-24">
                        <div className="absolute inset-0 blur-lg">
                            <div
                                style={{
                                    backgroundImage: `url(${src})`,
                                    backgroundPositionY: `${yOffset}px`, // Apply Y offset
                                    backgroundSize: 'cover',
                                    width: '96px',
                                    height: '96px',
                                }}
                            />
                        </div>
                        <div className="absolute inset-0">
                            <div
                                style={{
                                    backgroundImage: `url(${src})`,
                                    backgroundPositionY: `${yOffset}px`, // Apply Y offset
                                    backgroundSize: 'cover',
                                    width: '96px',
                                    height: '96px',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    );
};

export default PfpExamples;
