import React, { useEffect, useRef, useState } from 'react';
import { getFirestore, collection, getDocs, writeBatch, doc } from "firebase/firestore";

const ImageGrid = ({ ArrImgUrl, viewImage, indexRef, PrevView, winpos }) => {
    const db = getFirestore();
    const urlsCollection = collection(db, "url");

    const checkAndDeleteDuplicates = async (url) => {
        const querySnapshot = await getDocs(urlsCollection);
        try {
            const idsToDelete = [];
            let keepId = null;

            querySnapshot.forEach((docSnapshot) => {
                const data = docSnapshot.data();
                if (data.link === url) {
                    if (!keepId) {
                        keepId = docSnapshot.id; // Keep the first found duplicate
                    } else {
                        idsToDelete.push(docSnapshot.id); // Mark others for deletion
                    }
                }
            });

            if (idsToDelete.length > 0) {
                const batch = writeBatch(db);
                idsToDelete.forEach((id) => {
                    const docRef = doc(db, "url", id);
                    batch.delete(docRef);
                });

                await batch.commit();
                console.log(`Kept ${keepId} and deleted duplicates:`, idsToDelete);
            } else {
                console.log("No duplicates found for:", url);
            }
        } catch (error) {
            console.error("Error checking and deleting duplicates:", error);
        }
    };

    const dellAll = async (arr) => {
        for (const link of arr) {
            await checkAndDeleteDuplicates(link);
        }
    };

    const [mainArr, setMainArr] = useState([]);

    useEffect(() => {
        if (ArrImgUrl && ArrImgUrl.length > 0) {
            const chunkSize = Math.ceil(ArrImgUrl.length / 5);
            const newMainArr = [];
            
            for (let i = 0; i < ArrImgUrl.length; i += chunkSize) {
                newMainArr.push(ArrImgUrl.slice(i, i + chunkSize));
            }
            
            setMainArr(newMainArr); // Update the main array with the new chunks
        }
    }, [ArrImgUrl]);

    const refimafe = useRef(false);
    const handlIamgeView = (src, ev) => {
        viewImage(true);
        indexRef(src);
        refimafe.current = src;
        PrevView(ev.target);
        winpos({ x: window.scrollX, y: window.scrollY + 200 });

        window.scrollTo(0, 0);
    };

    return (
        <div className="p-4">
            <button
                onClick={() => dellAll(ArrImgUrl)}
                className="bg-red-600 text-white p-2 rounded mb-4 hover:bg-red-700 transition transform hover:scale-105"
            >
                Delete Duplicates
            </button>

            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {ArrImgUrl.length > 0 && mainArr.length > 0 ? (
                    mainArr.map((col, indexx) => (
                        <React.Fragment key={indexx}>
                            {col.map((src, index) => (
                                <div
                                    onClick={(ev) => {
                                        handlIamgeView(src, ev);
                                    }}
                                    className="relative grid grid-cols-1 overflow-hidden rounded-lg shadow-lg transition-transform duration-300 transform hover:scale-105"
                                    key={index}
                                >
                                    <img
                                        src={src}
                                        alt=""
                                        className="w-full h-auto rounded-lg transition-opacity duration-300 hover:opacity-80"
                                        style={{ maxHeight: '200px', objectFit: 'cover' }}
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center opacity-0 transition-opacity duration-300 hover:opacity-100">
                                        <span className="text-white text-lg font-semibold cursor-default"></span>
                                    </div>
                                </div>
                            ))}
                        </React.Fragment>
                    ))
                ) : (
                    <div className="text-center text-gray-500">Loading images...</div>
                )}
            </div>
        </div>
    );
};

export default ImageGrid;
