import React, { useEffect } from 'react';

const ScrapedDataDisplay = ({ data , setUrl , scrap}) => {
    async function loadData(){
        setUrl(data.codes[0])
        await scrap()
    }
    useEffect(()=>{
        if(data)
        loadData();
    },[])
  return ( data &&
    <div className="p-4">
      {/* Display images */}
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-4">Similar Images</h2>
        <div className="grid grid-cols-3 gap-4">
          {data.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Scraped image ${index + 1}`}
              className="w-full h-auto object-cover"
            />
          ))}
        </div>
      </div>

      {/* Display grouped links */}
      <div>
        <h2 className="text-xl font-bold mb-4">Grouped Links</h2>
        {data.codes.map((code) => (
          <div key={code} className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Group {code}</h3>
            <ul className="list-disc pl-5">
              {data.groupedLinks[code].map((link, index) => (
                <li key={index}>
                  <a
                    href={link.href}
                    aria-label={link.ariaLabel}
                    className="text-blue-500 underline"
                  >
                    {link.ariaLabel}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrapedDataDisplay