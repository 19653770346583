import React, { useEffect, useState } from 'react';
import CreateUser from './CreateUser';

const GoogleOneTap = ({currentUser,setCurrentUser}) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const handleCredentialResponse = (response) => {
      // Decode the JWT token to get user information
      const userObject = parseJwt(response.credential);
      setUser(userObject);
      setCurrentUser(userObject)
    };

    const initGoogleOneTap = () => {
      window.google.accounts.id.initialize({
        client_id: '49478870300-tk3rdqf0rdkhtcnn0pcmp2lvhtpkuhef.apps.googleusercontent.com',  // Replace with your actual Client ID
        callback: handleCredentialResponse,
      });

      // Automatically show One Tap prompt
      window.google.accounts.id.prompt();
    };

    const loadScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initGoogleOneTap;
      document.body.appendChild(script);
    };

    if (window.google && window.google.accounts) {
      initGoogleOneTap();
    } else {
      loadScript();
    }
  }, [setCurrentUser]);

  // Function to decode the JWT token
  const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(atob(base64Url).split('').map(c =>
      `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`).join(''));
    return JSON.parse(base64);
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      {user ? (
        <div
          style={{
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            border: '5px solid green',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          <CreateUser data={user}/>
          <img
            src={user.picture}
            alt="User Profile"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default GoogleOneTap;
