import React, { useCallback, useEffect, useRef, useState } from 'react';

const ImageFilterCanvas = ({ src, style }) => {
    const canvasRef = useRef(null);
    const imgRef = useRef(null); // Reference to store the original image
    const [filters, setFilters] = useState({
        grayscale: 0,
        blur: 0,
        brightness: 100,
        contrast: 100,
        saturation: 100,
        sepia: 0,
        invert: 0,
    });
    const [smoothTransition, setSmoothTransition] = useState(false);
    const [flip, setFlip] = useState({ horizontal: false, vertical: false });
    const flipRef = useRef({ horizontal: false, vertical: false });

    // Debounce function to delay updates
        // Apply the filters and draw the image on the canvas with flip
        const drawImageWithFilters = useCallback(() => {
            if (!imgRef.current) return;
    
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            const { width, height } = imgRef.current;
    
            // Set canvas dimensions to match the image
            canvas.width = width;
            canvas.height = height;
    
            // Reset any existing transformations (important before applying flips)
            ctx.resetTransform();
    
            // Handle mirror flip
            const { horizontal, vertical } = flip;
            if (horizontal || vertical) {
                ctx.translate(horizontal ? width : 0, vertical ? height : 0);
                ctx.scale(horizontal ? -1 : 1, vertical ? -1 : 1);
            }
    
            // Apply filters
            const { grayscale, blur, brightness, contrast, saturation, sepia, invert } = filters;
            ctx.filter = `
                grayscale(${grayscale}%) 
                blur(${blur}px) 
                brightness(${brightness}%) 
                contrast(${contrast}%) 
                saturate(${saturation}%) 
                sepia(${sepia}%) 
                invert(${invert}%)
            `;
    
            // Draw the image with the filters
            ctx.drawImage(imgRef.current, 0, 0, width, height);
        },[ filters, flip]);
    const debounce = (fn, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => fn(...args), delay);
        };
    };

    // Load image on mount and whenever the source changes
    useEffect(() => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = `https://fastify-serverless-function-sand-one.vercel.app/api/scrape-file/?fileUrl=${src}`;

        img.onload = () => {
            imgRef.current = img;  // Store the loaded image
            drawImageWithFilters();
        };

        img.onerror = () => {
            console.error("Failed to load image from:", img.src);
        };
    }, [src,drawImageWithFilters]);  // Load the image only when `src` changes



    // Handle filter change and update canvas
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
        debouncedUpdateCanvas();
    };

    // Handle flip change and update canvas
    const handleFlipChange = (e) => {
        const { name, checked } = e.target;
        setFlip((prev) => ({ ...prev, [name]: checked }));
        flipRef.current.horizontal= name ==='horizontal' ? checked : false
        flipRef.current.vertical= name ==='vertical' ?  checked : false
        drawImageWithFilters(); // Update immediately on flip change
    };

    // Debounced function to update the canvas
    const debouncedUpdateCanvas = debounce(drawImageWithFilters, 100);

    // Toggle smooth transitions
    const handleSmoothTransitionToggle = () => {
        setSmoothTransition(!smoothTransition);
    };

    // Reset filters and flip to default values
    const resetFilters = () => {
        setFilters({
            grayscale: 0,
            blur: 0,
            brightness: 100,
            contrast: 100,
            saturation: 100,
            sepia: 0,
            invert: 0,
        });
        setFlip({ horizontal: false, vertical: false });
        drawImageWithFilters();
    };

    // Download the canvas content as an image
    const downloadCanvas = () => {
        const canvas = canvasRef.current;
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'canvas-image-filtered.png';
        link.click();
    };

    return (
        <div className={`relative text-gray-300 ${style}`}>
            {/* Canvas where the image will be drawn */}
            <canvas 
                ref={canvasRef} 
                className={`opacity-70 float-left ${smoothTransition ? 'transition-all duration-300 ease-in-out' : ''}`} 
                style={{ transform: 'scale(1)' }} 
            />

            {/* Download button */}
            <button 
                onClick={downloadCanvas} 
                className="bg-indigo-500 m-4 text-white p-2 rounded hover:bg-red-700 transition transform hover:scale-105"
            >
                Download Canvas as Image
            </button>

            {/* Smooth Transition Toggle */}
            <div className="mb-4">
                <label className="inline-flex items-center">
                    <input 
                        type="checkbox" 
                        checked={smoothTransition} 
                        onChange={handleSmoothTransitionToggle} 
                        className="mr-2"
                    />
                    <span className="font-medium text-gray-300">Smooth Transitions</span>
                </label>
            </div>

            {/* Filter controls */}
            <ul className="filters space-y-6 p-6 bg-white shadow-lg rounded-lg border border-gray-200">
                {[
                    { name: "grayscale", label: "Grayscale", max: 100 },
                    { name: "blur", label: "Blur", max: 10 },
                    { name: "brightness", label: "Brightness", max: 200 },
                    { name: "contrast", label: "Contrast", max: 200 },
                    { name: "saturation", label: "Saturation", max: 200 },
                    { name: "sepia", label: "Sepia", max: 100 },
                    { name: "invert", label: "Invert", max: 100 },
                ].map(({ name, label, max }) => (
                    <li key={name} className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-2 sm:space-y-0">
                        <label htmlFor={name} className="font-semibold text-gray-800 sm:text-lg">
                            {label}:
                        </label>
                        <input
                            id={name}
                            type="range"
                            name={name}
                            min="0"
                            max={max}
                            value={filters[name]}
                            onChange={handleFilterChange}
                            className="w-full sm:w-2/3 bg-gray-200 rounded-lg overflow-hidden appearance-none h-2 cursor-pointer transition-all hover:bg-gray-300 focus:ring-2 focus:ring-blue-500"
                        />
                    </li>
                ))}
            </ul>

            {/* Flip controls */}
            <div className="flex items-center space-x-4 p-4">
                <label className="flex items-center space-x-2">
                    <input 
                        type="checkbox" 
                        name="horizontal" 
                        checked={flip.horizontal || flipRef.current.horizontal} 
                        onChange={(e)=>{handleFlipChange(e)}} 
                    />
                    <span className="font-semibold text-gray-300">Flip Horizontally</span>
                </label>

                <label className="flex items-center space-x-2">
                    <input 
                        type="checkbox" 
                        name="vertical" 
                        checked={flip.vertical || flipRef.current.vertical} 
                        onChange={(e)=>{handleFlipChange(e)}} 
                    />
                    <span className="font-semibold text-gray-300">Flip Vertically</span>
                </label>
            </div>

            {/* Reset Filters Button */}
            <button 
                onClick={resetFilters} 
                className="bg-gray-500 text-white p-2 mt-4 rounded hover:bg-gray-700 transition transform hover:scale-105"
            >
                Reset Filters
            </button>
        </div>
    );
};

export default ImageFilterCanvas;
